import React from "react";
/* eslint-disable max-len */

import { useStaticQuery, graphql, Link } from "gatsby";

import SEO from "../components/Seo";
import BlogPage from "../components/template-parts/BlogPage";
import { ImgContent } from "../components/template-parts/BlogPage/styles";

function MedicinaTradicionalChinesa() {
  const { banner, mtc } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "bgImage/bg-mtc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mtc: file(relativePath: { eq: "common/mtc.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const bannerPage = banner.childImageSharp.fluid;
  const content = {
    title: `Medicina Tradicional Chinesa`,
    featuredImage: bannerPage,
  };
  return (
    <>
      <SEO
        title={content.title}
        keywords="helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
        description="A Medicina Tradicional Chinesa (MTC) reune em si um conjunto de
        “medicinas” utilizadas na China ao longo dos últimos cinco mil anos que englobam o diagnóstico
        holístico e o tratamento dos desequilíbrios do corpo e da mente, bem
        como a prevenção de doenças."
        type="article"
      />
      <BlogPage content={content}>
        <h2>O que é a Medicina Tradicional Chinesa?</h2>

        <p>
          A Medicina Tradicional Chinesa (MTC) reune em si um conjunto de “medicinas” utilizadas na
          China ao longo dos últimos&nbsp;
          <strong>cinco mil anos&nbsp;</strong>que englobam o diagnóstico holístico e o tratamento
          dos desequilíbrios do corpo e da mente, bem como a prevenção de doenças.&nbsp;
        </p>

        <p>
          Ela é embasada em um sistema filosófico que tem suas raízes no Taoísmo, no Confuncionismo
          e no Budismo, portanto entende o ser humano de maneira profunda e integrada com sua
          alma.&nbsp;
        </p>

        <p>
          A Medicina Chinesa que chegou até os dias de hoje é baseada nos clássicos chineses
          escritos sobretudo cerca de 2000 anos atrás, apesar de que, na época existia além dessa
          medicina Chinesa mais elitizada e clássica, outras práticas passadas de pai para filho e
          que foram pouco a pouco fazendo parte daquilo que hoje conhecemos como a Medicina
          Tradicional Chinesa.&nbsp;
        </p>
        <div>
          <ImgContent fluid={mtc.childImageSharp.fluid} />
        </div>
        <h2>Medicina Holística: o que isso quer dizer?</h2>

        <p>
          A abordagem holística significa que nenhuma parte do corpo ou da psique do homem é
          considerada individualmente. Assim sendo, a
          <strong>&nbsp;modificação de uma dessas partes acarreta na mudança do todo</strong>. O
          diagnóstico na Medicina Tradicional Chinesa parte de uma consulta bastante detalhada, da
          avaliação das características físicas e emocionais do paciente, bem como do exame geral do
          corpo e ainda do pulso e da língua que irão ajudar a constituir essa fotografia que é uma
          amostra da complexa integridade do paciente.
        </p>

        <h2>Os Cinco Elementos da Medicina Chinesa</h2>

        <p>
          O sistema da Medicina Tradicional Chinesa é fruto da observação intensa e do conhecimento
          intuitivo dos antigos médicos chineses, que fizeram uma bela correlação entre os elementos
          e os estados da natureza e os estados internos dos seres humanos. Como descrever vírus e
          bactérias há 5 mil anos? Os chineses utilizam termos como vento-frio e vento-calor para
          descrever, por exemplo, um estado gripal ou uma infecção bacteriana, agrupando, de modo
          sistemático, os fenômenos observados nos seus pacientes. Na Medicina Chinesa existe uma
          classificação das funções físicas e psíquicas divididas em 5 movimentos energéticos ou 5
          símbolos, a saber:&nbsp;
          <strong>a água, a madeira, o fogo, a terra e o metal</strong>. Estes elementos da natureza
          podem ser associados a estados mentais, doenças ou sintomas.&nbsp;
        </p>

        <h2>Tratamentos da Medicina Tradicional Chinesa</h2>

        <p>
          A Medicina Tradicional Chinesa é composta das seguintes modalidades terapêuticas:&nbsp;
          <strong>
            <Link to="/acupuntura">Acupuntura</Link>, Fitoterapia, Massagens, Exercícios Físicos e
            Alimentação.&nbsp;
          </strong>
        </p>

        <p>
          A Acupuntura é a modalidade mais conhecida no Ocidente e, no Brasil, é também hoje
          reconhecida como uma especialidade médica. Consiste na colocação de agulhas em pontos
          específicos localizados ao longo dos meridianos, que são zonas de influência dos diversos
          órgãos na superfície corporal. As agulhas são muito finas e a punção costuma ser indolor.
          Tal punção leva a determinadas alterações fisiológicas, entre elas, a produção de
          endorfinas, susbtâncias produzidas pelo organismo, semelhantes à morfina e que promovem
          bem-estar e analgesia. Estas alterações observadas em seres humanos e em animais de
          laboratório foram fruto do estudo da acupuntura por pesquisadores ocidentais. Pela
          Medicina Chinesa, os efeitos da acupuntura são o equilíbrio das forças internas Yin e
          Yang, o aumento da defesa do organismo e o combate dos fatores de adoecimento.&nbsp;
        </p>

        <p>
          A Fitoterapia consiste na utilização de ervas para equilibrar o organismo. Existem muitos
          tratados de Fitoterapia Chinesa que preconizam a utilização de folhas, sementes, raízes e
          outros elementos da flora e fauna típicos da China. Pode-se, entretanto, fazer também uso
          da fitoterapia brasileira, com sua vasta seleção de ervas, utilizando o princípio de que o
          habitante de uma região encontra na terra em que vive a cura para seus males, pois o clima
          e o macrocosmo influenciam diretamente a saúde. Quando utilizamos as ervas brasileiras,
          levamos em consideração não só o seu uso clínico, mas também suas características
          relevantes para a Medicina Chinesa. Assim, os meridianos em que a erva penetra, seu sabor
          e suas características particulares influenciam como um todo na prescrição final. Tanto o
          chá, quanto os pontos escolhidos da Acupuntura são importantes, não só individualmente,
          mas em conjunto, na medida em que da combinação correta desses fatores pode depender o
          sucesso terapêutico.
        </p>

        <p>
          Os Exercícios Físicos possibilitam melhor circulação e melhor aproveitamento da energia.
          Na China, os exercícios mais recomendados são o Tai Chi Chuan e o Chi Kun. No Ocidente,
          existem muitos outros tipos de exercícios que podem ser usados como auxílio terapêutico. É
          de todos conhecido o benefício que a atividade física proporciona, ressaltando-se que esta
          deve ser sempre aliada ao tratamento.
        </p>
        <p>
          Finalmente, a alimentação usa-se também como técnica terapêutica, combinando sabores,
          temperatura, meridianos afetados pelos alimentos, regularidade das refeições, quantidade e
          qualidade de alimentos ingeridos. Unindo as diversas modalidades terapêuticas vistas
          acima, a Medicina Tradicional Chinesa trata do paciente de forma holística, pois leva em
          conta seu estilo de vida, o meio-ambiente no qual ele está inserido e seu bem-estar físico
          e psíquico.{" "}
        </p>
      </BlogPage>
    </>
  );
}

export default MedicinaTradicionalChinesa;
